import type { Component, HTMLAttributes } from 'vue';
import { useModal, VueFinalModal, useModalSlot } from 'vue-final-modal';

type VueFinalModalProps = InstanceType<typeof VueFinalModal>['$props'];

const modalInstances = new Map();

export default function useCiModal() {
  const runtimeConfig = useRuntimeConfig();

  /********************
   * FUNCTIONS         *
   ********************/
  function closeModal(name: string) {
    const instance = modalInstances.get(name);

    if (instance) {
      instance.close();

      modalInstances.delete(name);
    } else {
      if (runtimeConfig.public.nodeEnv === 'development') {
        console.info(`Modal with ${name} does not exist`);
      }
    }
  }

  function openModal<T extends Component>(
    slot: {
      component: T;
      attrs: T extends Component<infer P> ? Partial<P> & { name?: string } & HTMLAttributes : never;
    },
    modalAttrs?: VueFinalModalProps,
  ) {
    if (!modalInstances.get(name)) {
      const { name, ...attrs } = slot.attrs;
      const modalInstance = useModal({
        component: VueFinalModal,
        attrs: {
          background: 'non-interactive',
          class: 'flex justify-center items-center overflow-auto',
          clickToClose: false,
          overlayClass: '!fixed',
          contentClass: 'w-[600px] max-w-[1240px] bg-white border rounded-lg p-4',
          contentTransition: undefined,
          displayDirective: 'if',
          escToClose: true,
          focusTrap: false,
          hideOverlay: false,
          lockScroll: true,
          modalId: slot.component.__name || name,
          modelValue: false,
          overlayTransition: undefined,
          reserveScrollBarGap: true,
          swipeToClose: 'none',
          teleportTo: 'body',
        },
        slots: {
          default: useModalSlot({
            component: slot.component,
            attrs: {
              ...attrs,
            },
          }),
        },
      });
      modalInstances.set(slot.component.__name || slot.attrs.name, modalInstance);
      modalInstance.patchOptions({ attrs: modalAttrs });
      modalInstance.open();
    } else {
      console.info('Modal with the same name already exists');
    }
  }

  return {
    closeModal,
    openModal,
  };
}
